body {
    margin-bottom: 100px; /*
     Remove default body margin */
    padding: 0; /* Remove default body padding */
    background-image: url('../Img/OF18OA0.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }
  



.Admin{
    text-decoration: underline;
    
}



.ACert2{text-decoration: underline;
    text-align: center;
}

.ACert{

margin-bottom: 1%;
}

.RFQDocs{
    border: 2px solid black;
    border-radius: 10px;
    margin: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 5px 10px #888888;
    background-color: aliceblue;
}

.AdminCertCont{border: 2px solid black;
    border-radius: 10px;
    margin: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 5px 10px #888888;
    background-color: aliceblue;}






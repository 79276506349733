* {
    box-sizing: border-box;
  }
  
  .TC {
    font-family: "Roboto", sans-serif;
    display: flex;
    padding: 15px;
    padding-top: 30px;
  }
  
  .Meta {
    flex-grow: 1;
    padding-right: px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .Meta h1 {
    text-decoration: underline;
  }
  
  .Meta input {
    font-family: inherit;
    padding: 5px;
    width: 100%;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 2px;
  }
  
  #certificateWrapper {
    box-shadow: 0 0 5px #000;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 20px;
    position: relative;
    width: 55%;
   
  }
  
  #certificateWrapper img {
    height: 650px;
    display: block;
    width: 100%;
  }
  
  #CName {
    position: absolute;
    width: 78%;
    top: 40%;
    left: 7.5%;
    text-align: left;
    font-size: 2.3rem;
    font-family: 'Montserrat', sans-serif;
    color : #3b3a3a;
  }

  a {
    border-bottom: 2px solid #3b3a3a;
    padding-bottom: 5px;
    }

 

  #CName1 {
    position: absolute;
    max-width: 220px;
    top: 70%;
    left: 22.5%;
    text-align: left;
    font-size: 0.8rem;
    font-family: 'Montserrat', sans-serif;
    color: #3b3a3a;
  }
  


  #Date1 {
    position: absolute;
    width: 78%;
    top: 69.5%;
    left: 18%;
    text-align: center;
    font-size: 0.8rem;
    font-family: 'Montserrat', sans-serif;
    color : #3b3a3a;
  }

  #Date2 {
    position: absolute;
    width: 78%;
    top: 81.7%;
    left: 19%;
    text-align: center;
    font-size: 0.8rem;
    font-family: 'Montserrat', sans-serif;
    color : #3b3a3a;
  }
  
  #TrianedBy {
    position: absolute;
    width: 78%;
    top: 81.7%;
    left: 11%;
    text-align: left;
    font-size: 0.8rem;
    font-family: 'Montserrat', sans-serif;
    color : #3b3a3a;
  }
  
  .CN {
    text-align: left !important;
  }

  .TButton{
    display: flex;
    justify-content: center;
  }
  
  .button-82-pushable {
    position: relative;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    transition: filter 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-top: 24px;
    
  }
  
  .button-82-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: hsl(0deg 0% 0% / 0.25);
    will-change: transform;
    transform: translateY(2px);
    transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
  }
  
  .button-82-edge {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: linear-gradient(
      to left,
      hsl(340deg 100% 16%) 0%,
      hsl(340deg 100% 32%) 8%,
      hsl(340deg 100% 32%) 92%,
      hsl(340deg 100% 16%) 100%
    );
  }
  
  .button-82-front {
    display: block;
    position: relative;
    padding: 12px 27px;
    border-radius: 12px;
    font-size: 0.5rem;
    color: white;
    background: hsl(345deg 100% 47%);
    will-change: transform;
    transform: translateY(-4px);
    transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
  }
  
  @media (min-width: 768px) {
    .button-82-front {
      font-size: 0.8rem;
      padding: 12px 42px;
    }
  }
  
  .button-82-pushable:hover {
    filter: brightness(110%);
    -webkit-filter: brightness(110%);
  }
  
  .button-82-pushable:hover .button-82-front {
    transform: translateY(-6px);
    transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
  }
  
  .button-82-pushable:active .button-82-front {
    transform: translateY(-2px);
    transition: transform 34ms;
  }
  
  .button-82-pushable:hover .button-82-shadow {
    transform: translateY(4px);
    transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
  }
  
  .button-82-pushable:active .button-82-shadow {
    transform: translateY(1px);
    transition: transform 34ms;
  }
  
  .button-82-pushable:focus:not(:focus-visible) {
    outline: none;
  }